.image-container {
  background-color: #90d595;
  overflow: hidden;
}

.image-container img {
  width: 45rem;
  height: 30rem;
  transition: 0.5s;
}

.image-container img:hover {
  border-radius: 0px;
  transition: 0.5s;
  transform: scale(1.1);
}

.cta-btn {
  transition: 0.5s;
}
.cta-btn:hover {
  transition: 0.5s;
}
.service-card {
  transition: 0.3s;
}
.service-card-image {
  transition: 0.3s;
}

.service-card:hover {
  box-shadow: 0px 0px 20px #cdcdcd;
  transition: 0.3s;
}
.service-card:hover .service-card-image {
  transform: scale(1.1);
  transition: 0.3s;
}
.service-image {
  transition: transform 0.3s ease-in-out;
}

.top-destinations-card-2 .travel-card-image {
  transition: 0.3s;
}
.top-destinations-card-2:hover {
  transition: 0.3s;
}
.top-destinations-card-2:hover .travel-card-image {
  transform: scale(1.1);
  transition: 0.3s;
}

.transaction {
  transition: 0.3s;
}
.transaction:hover {
  transition: 0.3s;
}

.transition-slow {
  transition: 1s;
}

.btn-service {
  padding: 14px 0px !important;
  font-size: 14px;
}

.overview-services {
  border: 1px solid rgb(215, 215, 215);
  padding: 25px 20px;
  border-radius: 8px;
}
.trek-detail-image {
  height: 40vh;
  width: 100%;
  object-fit: cover;
}

.iterinary-content p {
  color: #777;
  font-weight: 300;
}

.contact-by-call {
  border: 2px solid #178748;
  text-align: center;
  color: #fff;
  height: 260px;
}

.contact-by-call .content {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 30px 0;
}

.text-style {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #777;
}

/* ant design calender increased the z-index */
:where(.css-dev-only-do-not-override-pr0fja).ant-picker-dropdown {
  z-index: 9999999999999999 !important;
}

/* ant design calender remove "today" section */
:where(.css-dev-only-do-not-override-pr0fja).ant-picker-dropdown
  .ant-picker-panel
  .ant-picker-footer {
  display: none !important;
}

.destination-list {
  border-left: 2px solid #178748;
}
.destination-list:hover {
  background: #178748;
  color: #fff;
}

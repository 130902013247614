.carousel-inner {
  position: relative;
  content: "";
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  color: #fff;
  padding: 40px 0;
  border-radius: 10% 5%;
}

.banner-content:before {
  position: absolute;
  content: "";
  background-color: #00922c;
  display: block;
  top: 0;
  height: 5px;
  width: 100%;
  transition: 0.3s;
}

.banner-content:after {
  position: absolute;
  content: "";
  background-color: #00922c;
  display: block;
  bottom: 0;
  height: 5px;
  width: 100%;
  transition: 0.3s;
}

.banner-content:hover:before {
  top: 10px;
  transition: 0.3s;
}

.banner-content:hover:after {
  bottom: 10px;
  transition: 0.3s;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -10px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  transition: width 0.3s ease-in-out;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 10px;
  height: 10px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 10px;
  height: 10px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

/* Custom Slick Dots */

@keyframes loading {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

.ft-slick__dots--custom {
  height: 8px;
  width: 14px;
  background-color: #e5e7e9;
  border-radius: 4px;
  position: relative;
}

.slick-dots li {
  width: 14px;
  margin: 0 2px;
  transition: width 0.3s ease-in-out;
}

.slick-dots .slick-active {
  width: 56px;
  transition: width 0.3s ease-in-out;
}

.slick-dots .slick-active .ft-slick__dots--custom {
  width: 56px;
  top: -2px;
  overflow: hidden;
  background: #a1e2b4;
}

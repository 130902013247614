.nav-info-container {
  background-color: #fff;
  width: 100%;
  position: relative;
  z-index: 999999999 !important;
}
.nav-info {
  padding: 18px 0;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 900px) {
  .logo {
    width: 270px;
  }
}

.nav-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 2;
}

.nav-bar {
  z-index: 2;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  height: 75px;
}

.menu {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 3rem;
}

@media (max-width: 970px) {
  .menu {
    display: none;
  }
}
.link {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  text-transform: uppercase;
  color: #555454;
  text-align: center;
}
@media (max-width: 970px) {
  .link {
    letter-spacing: 1px;
  }
}
.link:hover {
  color: #00922c;
  transition: all 300ms linear;
}
.btn {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  text-transform: uppercase;
  color: #fff;
  padding: 14px 18px;
  background-color: #00922c;
}
.active {
  text-decoration: underline !important;
  text-decoration-thickness: 3.5px !important;
  font-weight: 700 !important;
  text-decoration-color: #00922c !important;
  text-underline-offset: 9px !important;
  color: #00922c !important;
}

@media screen and (max-width: 1190px) and (min-width: 799px) {
  .link,
  .btn {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 1px;
  }
  .btn {
    padding: 10px 18px;
  }
  .menu {
    gap: 1rem;
  }
}
@media (min-width: 1190px) {
}
.nav-icon {
  background: none;
  cursor: pointer;
  border: none;
  outline: none;
}
@media (min-width: 970px) {
  .nav-icon {
    display: none;
  }
}

.line {
  display: block;
  border-radius: 50px;
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: #000;
  transition: width 0.4s ease-in-out;
}

.overlay {
  position: fixed;
  width: 100%;
  top: 75px;
  z-index: 1;
  background: #fff;
  transition: height 0.4s ease-in-out;
}

@media (min-width: 970px) {
  .overlay {
    display: none;
  }
}

.overlay-menu {
  list-style: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  transform: translateY(-200%);
}
@media (min-width: 768px) and (max-width: 970px) {
  .overlay {
    top: 110px;
  }
  .overlay-menu {
    top: 150px;
  }
}
.overlay-menu li {
  font-size: 25px;
  transition: opacity 0.4s ease-in-out;
}

.text-link {
  text-align: center;
  padding: 20px 0;
  font-size: 16px;
  letter-spacing: 19px;
}
.overlay-menu li:hover {
  background-color: #cce7e8;
  width: calc(83.33vw) !important;
  margin-left: auto;
  margin-right: auto;
}
